import { cognitoUserPoolsTokenProvider } from '@aws-amplify/auth/cognito';
import { Amplify } from 'aws-amplify';
import { CookieStorage } from 'aws-amplify/utils';

import { config } from './config';

export const configureClientAmplify = () => {
  Amplify.configure({ Auth: config }, { ssr: true });

  cognitoUserPoolsTokenProvider.setKeyValueStorage(
    new CookieStorage({
      domain: process.env.NEXT_PUBLIC_COOKIE_DOMAIN,
      expires: 3600,
      path: '/',
      sameSite: 'lax',
      secure: true,
    }),
  );
};
