import {
  CarriersPlan,
  Company,
  DefaultOrderCondition,
  InvoiceSettings,
  User,
} from '@univearth/lifti-api';
import { atom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';

export const companyState = atom<Company | null>(null);
export const invoiceSettingsState = atom<InvoiceSettings | null>(null);
export const orderConditionSettingsState = atom<DefaultOrderCondition | null>(null);
export const userState = atom<User | null>(null);
export const authUserState = atom(null);
export const planState = atomWithStorage<CarriersPlan | null>('planState', null);
