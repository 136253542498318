import { createContext } from 'react';

interface NotificationContextProps {
  showNotification: (type: 'success' | 'error', title: string, message: string) => void;
}

const NotificationContext = createContext<NotificationContextProps>({
  showNotification: () => {},
});

export default NotificationContext;
